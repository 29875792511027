<template>
    <div style="color:red;font-size: 25px;padding-top: 20px;">Ошибка авторизации <br/> ваш код<b>:{{ chatId }}</b></div>
</template>

<script>
import { mapGetters } from 'vuex';
export default{
    name: 'LoginError',
    computed: {
        ...mapGetters(['chatId'])
    }
}
</script>