<template>
    <loader
    v-if="loader"
     object="#ff9633"
     color1="#ffffff"
     color2="#17fd3d"
     size="5"
     speed="2"
     bg="#343a40"
     objectbg="#999793"
     opacity="80"
     name="circular"
    ></loader>
    <RouterView />
</template>

<script>
let tg = window.Telegram.WebApp; //получаем объект webapp телеграма 

import ThermosService from './service/ThermosService';
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'loader',
      'chatId'
    ])
  },
  mounted() {
    tg.expand(); 

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const chatId = urlParams.get('chatId');
    if(chatId) {
      this.$store.dispatch('setChatId',chatId)
    }

    ThermosService.auth(this.chatId)
    .then(res => {
      this.$store.dispatch('setUserId', res.data.userId);
      this.$router.push("/dashboard");
    })
    .catch(e => {
      console.log(e);
      this.$router.push("/login-error");
    })
  },
}
</script>

<style>
body{
  background: var(--tg-theme-bg-color);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--tg-theme-text-color);
}
a {
 color: var(--tg-theme-link-color);
}
button {
  background: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
}
.apexcharts-menu-open{
  background: var(--tg-theme-bg-color);
}
.apexcharts-menu-item{
  color: var(--tg-theme-text-color) !important;
}

.apexcharts-xaxis-label, .apexcharts-yaxis-label{
    fill: var(--tg-theme-text-color);
}
.apexcharts-legend-text{
  fill: var(--tg-theme-text-color) !important;
}
.apexcharts-menu-icon{
  display: none;
}
</style>
