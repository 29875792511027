import Vuex from 'vuex'

export default new Vuex.Store({
    state () {
      return {
        chatId: localStorage.getItem('chatId') || null,
        userId: localStorage.getItem('userId') || null,
        loader: false
      }
    },
    getters: {
        loader(state) {
            return state.loader;
        },
        chatId(state) {
            return state.chatId;
        },
        userId(state) {
          return state.userId;
      },
    },
    mutations: {
      setLoader (state, loader) {
        state.loader = loader
      },
      setChatId (state, chatId) {
        localStorage.setItem('chatId',chatId);
        state.chatId = chatId
      },
      setUserId (state, userId) {
        localStorage.setItem('userId',userId);
        state.userId = userId;
      },
    },
    actions: {
        setLoader (context, state) {
            context.commit('setLoader', state)
        },
        setChatId (context, chatId) {
            context.commit('setChatId', chatId)
        },
        setUserId (context, userId) {
          context.commit('setUserId', userId)
      },
    }
  })