import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router"
import loader from "vue-ui-preloader";
import store from './store';

const app = createApp(App)

app.use(store)

app.use(router)

app.use(loader)

// Mount vue app
app.mount('#app')