<template>
  <router-link
    :to="'/zone/' + item.id" 
  >
    <div class="zone" :class="{'deactive' : !item.active}">
      <span class="zone-title">{{ item.name }}</span>
      <template v-if="item.item">
        <span v-if="item.item.value" class="zone-value" :class="item.item.status"> {{ item.item.value }}</span>
      </template>
      <span v-else style="color:red;">Ошибка</span>
    </div>
  </router-link>
</template>
  
<script>
export default {
  name: 'ZoneItem',
  props: {
    item: Object,
  },
}
</script>

<style lang="scss" scoped>
    .zone {
      display: grid;
      border: 1px solid;
      width: fit-content;
      padding: 5px;
      border-radius: 5px;
      margin-right: 5px;
      margin-bottom: 5px;

      &-title {
        font-size: 12px;
      }

      &-value {
        color: gray;
        font-weight: bold;
      }
      .green {
        color: #009101;
      }

      .yellow {
        color: #ff8c00;
      }

      .red {
        color: red;
      }
    }

    .deactive{
      color: gray !important;

      .zone-value{
        color: gray !important;
      }
    }
</style>