<template>
  <branch-list :items="branchItems"/>
</template>

<script>
import ThermosService from '@/service/ThermosService';
import branchList from '@/components/branch-list.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'DashboardPage',
  components: {
    branchList
  },
  data: () => ({
    branchItems: []
  }),
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    this.$nextTick(() => {
      this.$store.dispatch('setLoader', true);

      setTimeout(() => {
        this.$store.dispatch('setLoader', false);
      }, 500)
      
      ThermosService
      .getDashboard(this.userId)
      .then(dashboard => {
        this.branchItems = dashboard.data;
      })
      .catch(error => {
        alert(error.message);
      })

    })
  }
}
</script>