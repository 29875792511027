<template>
    <div class="warehouses">
      <accordion
        v-for="warehouse in items"
        :key="warehouse.id"
        classList="warehouses__list"
      >
        <accordion-item>
          <template #accordion-trigger>
            <span>{{ warehouse.name }}</span>
          </template>
          <template #accordion-content>
            <div class="warehouses__list--zone">
              <zone-item 
                v-for="zone in warehouse.zones"
                :key="zone.id"
                :item="zone"
              />
            </div>
          </template>
        </accordion-item>
      </accordion>
    </div>
</template>
  
<script>
  import Accordion from './accordion.vue';
  import AccordionItem from './accordion-item.vue';
  import ZoneItem from './zone-item.vue';

  export default {
    name: 'WarehousesList',
    props: {
      items: Array,
    },
    components: {
      Accordion,
      AccordionItem,
      ZoneItem,
    }
  }
</script>

<style>
.warehouses__list--zone {
  padding-left: 10px;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  padding: 5px 0;
}
.warehouses {
  padding-left: 10px;
}
.warehouses__list {
  padding: 5px 0 !important;
  border-bottom: 1px solid black;
}
.warehouses__list:last-child {
  border-bottom: 1px solid transparent;
}
</style>