import Api from "@/service/Api";

export default {
    /**
     * Авторизация
     * @param chatId 
     * @returns 
     */
    auth(chatId) {
        return Api().get('/auth/telegram/' + chatId);
    },

    /**
     * Получить dashboard сотрудника
     * @param userId 
     * @returns 
     */
    getDashboard(userId) {
        return Api().post('/portal/dashboard/' + userId);
    },
    

    getZone(zoneId, start, end) {
        return Api().post('/portal/zone/telegram/' + zoneId, {
            start: start,
            end: end,
        })
    }
};