<template>
    <div class="chart" v-if="!loader">
        <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
        <div class="chart__controller">
            <input type="date" :max="max" v-model="start">
            <input type="date" :max="max" v-model="end">
            <button @click="getData">Применить</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueApexCharts from 'vue3-apexcharts';
import ThermosService from '@/service/ThermosService';

export default {
    name: 'ZoneDetail',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        zoneId: String,
    },
    computed: {
        ...mapGetters(['loader']),
    },
    methods: {
        getData() {
            this.$store.dispatch('setLoader', true);
            ThermosService
            .getZone(this.$props.zoneId, this.start, this.end)
            .then(res => {
                this.$store.dispatch('setLoader', false);
                this.series = res.data.temperature
                console.log(this.series);
            })
            .catch(error => {
                console.log(error);
            })
        },
    },
    data: () => ({
        series: null,
        start: new Date().toISOString().slice(0,10),
        end: new Date().toISOString().slice(0,10),
        max: new Date().toISOString().slice(0,10),
        chartOptions: {
            colors:['red', 'yellow', 'blue', 'yellow', 'red'],
            chart: {
                defaultLocale: 'ru',
                locales: [{
                    name: 'ru',
                    options: {
                        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                        shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Ноя', 'Декабрь'],
                        days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                        shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                        toolbar: {
                            download: 'Скачать SVG',
                            selection: 'Selection',
                            selectionZoom: 'Selection Zoom',
                            zoomIn: 'Zoom In',
                            zoomOut: 'Zoom Out',
                            pan: 'Panning',
                            reset: 'Reset Zoom',
                        }
                    }
                }],
                id: 'areachart-2',
                type: 'line',
                stacked: false,
                height: 400,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                text: 'График',
                align: 'left',
                style: {
                    color:  'var(--tg-theme-text-color)'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0,
                    inverseColors: false,
                    opacityFrom: 0,
                    opacityTo: 0,
                    stops: [0]
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val.toFixed(1)
                    },
                },
                title: {
                    text: 'Температура',
                    style: {
                        color:  'var(--tg-theme-text-color)'
                    }
                },
                style: {
                    cssClass: 'apexcharts-yaxis-label',
                },
            },
            xaxis: {
                type: 'datetime',
                style: {
                    cssClass: 'apexcharts-xaxis-label',
                },
                labels: {
                    formatter: function(value, timestamp) {
                        const date = new Date(timestamp * 1000);
                        let day = date.getDate();
                        if(day < 10) {
                            day = '0' + day;
                        }
                        let month = date.getMonth() + 1;
                        if(month < 10) {
                            month = '0' + month;
                        }
                        const year = date.getFullYear();
                        let hour = date.getHours();
                        if(hour < 10) {
                            hour = '0' + hour;
                        }
                        let minute = date.getMinutes();
                        if(minute < 10) {
                            minute = '0' + minute;
                        }

                        return `${day}.${month}.${year} ${hour}:${minute}`
                    }
                },
            },
            axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
      },
     
            tooltip: {
                shared: false,
                y: {
                    formatter: function (val) {
                        return val;
                    }
                },
                theme: 'dark'
            }
        },
    }),
    created() {
    this.$store.dispatch('setLoader', true);

        this.getData();

    },
}
</script>


<style scoped>
.chart__controller{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
</style>