<template>
  <div class="branches">
    <accordion
      v-for="branch in items"
      :key="branch.id"
      classList="branches-list"
    >
      <accordion-item>
        <template #accordion-trigger>
          <span class="title">{{ branch.name }}</span>
        </template>
        <template #accordion-content>
          <warehouse-list :items="branch.warehouse"/>
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>

<script>
import Accordion from './accordion.vue';
import AccordionItem from './accordion-item.vue';
import WarehouseList from './warehouse-list.vue';
export default {
  name: 'BranchList',
  props: {
    items: Array,
  },
  components: {
    Accordion,
    AccordionItem,
    WarehouseList,
  },
}
</script>

<style>
.branches-list {
  padding: 5px 0 !important;
  border-bottom: 1px solid black;
}
.branches-list:last-child {
  border-bottom: 1px solid transparent;
}
.title{
  line-height: 20px;
}
</style>