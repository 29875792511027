<template>
    <li class="accordion__item">
      <div
        class="accordion__trigger"
        :class="{ accordion__trigger_active: visible }"
        @click="open"
      >
        <slot name="accordion-trigger"></slot>
        <svg-icon type="mdi" :path="path"></svg-icon>
      </div>
  
      <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end"
      >
        <div class="accordion__content" v-show="visible">
          <ul>
            <slot name="accordion-content"></slot>
          </ul>
        </div>
      </transition>
    </li>
  </template>
  
  
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiArrowDownThin, mdiArrowUpThin } from '@mdi/js';

export default {
  name: 'AccordionItem',
  props: {},
  inject: ["Accordion"],
  components: {
    SvgIcon
  },
  data() {
    return {
      index: null,
      path: mdiArrowUpThin,
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    },
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
        this.path = mdiArrowUpThin;
      } else {
        this.path = mdiArrowDownThin;
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  created() {
    this.index = this.Accordion.count++;
  },
};
</script>

<style lang="scss" scoped>
.accordion__item {
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  ul {
    padding: 0;
    margin: 0;
  }
  border-bottom: 1px solid transparent;
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
