<template>
    <div class="zone-detail-page">
        <router-link to="/dashboard">Вернуться к списку</router-link>
        <zone-detail :zone-id="zoneId"/>
    </div>
</template>

<script>
import zoneDetail from '@/components/zone-detail.vue';

export default {
  name: 'ZonePage',
  components: {
    zoneDetail
  },
  data:() => ({
    charts: {},
    zoneId: null,
  }),
  created() {
    this.zoneId = this.$route.params.id;
  },
}
</script>

<style scoped>
.zone-detail-page {
    display: grid;
    gap: 10px;
}
</style>