import {createRouter, createWebHistory} from 'vue-router';
import DashboardPage from './pages/Dashboard.vue'
import ZonePage from './pages/Zone.vue'
import AuthPage from './pages/Auth.vue'
import LoginError from './pages/LoginError.vue'

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: AuthPage
        },
        {
            path: '/login-error',
            component: LoginError
        },
        {
            path: '/dashboard',
            component: DashboardPage,
        },
        {
            path: '/zone/:id',
            component: ZonePage,
        }
    ]
});